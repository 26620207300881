import React, { useState } from "react";

import { Modal, Button, Row, Col, Divider, Carousel } from "antd";

import { FaRegEye } from "react-icons/fa";

import { ProductType } from "../../store/interfaces/Product";
import { firstLetterCap } from "../../utils";

interface Props {
  data: ProductType;
}

const ViewProduct: React.FC<Props> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => setIsModalOpen(true);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <FaRegEye className="icon" onClick={showModal} />
      <Modal
        forceRender
        title={"View Product"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="view_product_modal"
        centered
      >
        <Divider />
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <Carousel  autoplaySpeed={3000}  >
              {data?.imagesUrl?.map((v) => {
                const isVideo = /\.(mp4|webm|ogg)$/i.test(v); // Check for video extensions
                return (
                  <div key={v} className="carousel_media" style={{ height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    {isVideo ? (
                      <video
                        controls
                        autoPlay
                        muted
                        loop
                        style={{
                          width: "100%",
                          height: "100%",
                          // display:'flex',
                          // justifyContent:'center',
                          // alignItems:'center',  
                          objectFit: "cover",
                        }}
                      >
                        <source src={v} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={v}
                        alt="Product"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </Carousel>

            {/* <Carousel autoplay autoplaySpeed={3000}>
              {data?.imagesUrl?.map((v) => {
                return (
                  <div key={v} className="carousel_images">
                    <img src={v} alt={"Product"} />
                  </div>
                );
              })}
            </Carousel> */}
          </Col>
          <Col xs={24} md={12}>
            <div className="detail_box">
              <h2>{data?.name}</h2>
              <hr />
              <p>
                Description: <b>{data?.description}</b>
              </p>
              <hr />
              <p>
                Type: <b>{firstLetterCap(data?.productType) || "-"}</b>
              </p>
              <hr />
              <p>
                Category: <b>{firstLetterCap(data?.category?.name) || "-"}</b>
              </p>
              <hr />
              <p>
                Sub Category:{" "}
                <b>{firstLetterCap(data?.subCategory?.name) || "-"}</b>
              </p>
              <hr />
              <p>
                Stock: <b>{data?.stock}</b>
              </p>
              <hr />
              <p>
                Points: <b>{data?.points}</b>
              </p>
              <hr />
              <p>
                Delivery Fee:{" "}
                <b>
                  {(data?.productType !== "virtual" && data?.deliveryFee) ||
                    "0"}
                </b>
              </p>
              <hr />
              <h3>Variations</h3>
              {data?.variations?.length > 0 ? (
                data?.variations?.map((v, i) => {
                  return (
                    <div key={i} className="main_variation_box">
                      <header>
                        <p>
                          Size: <strong>{v?.size}</strong>
                        </p>
                      </header>
                      <div className="color_box_container">
                        <div className="main_color_box_header">
                          <p>Color</p>
                          <p>Stock</p>
                          <p>Points</p>
                        </div>
                        {v?.colors?.map((x, i) => {
                          return (
                            <div className="main_color_box" key={i}>
                              <div className="color_box">
                                <div style={{ backgroundColor: `${x?.hex}` }} />
                                <p>{x?.hex}</p>
                              </div>
                              <div className="stock_box">
                                <p>{x?.stock}</p>
                              </div>
                              <div className="points_box">
                                <p>{x?.points}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No variation available</p>
              )}
            </div>
          </Col>

          <Col xs={24}>
            <div className="view_product_footer">
              <Button className="buttonOutlined" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default ViewProduct;
