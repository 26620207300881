import React, { useEffect, useState } from "react";
import { Button, Input, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { getProducts, getProductCsv } from "../../../store/services/product";
import { ProductType } from "../../../store/interfaces/Product";
import { TbEdit } from "react-icons/tb";
import ViewProduct from "../../../components/Modals/ViewProduct";
import { firstLetterCap } from "../../../utils";
import UploadProductCsv from "../../../components/Modals/UploadProductCsv";

const { Search } = Input;

const Product: React.FC = () => {
  const { isLoading, products, total } = useAppSelector(
    (state) => state.product
  );
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [csvLoading, setCSVLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(search);
  }, [page, perPage]);

  const getData = async (search?: string) => {
    await getProducts({
      search: search || "",
      page,
      perPage,
    });
  };

  const handleSearch = async (value: string) => {
    setSearch(value);
    setPage(1); // Reset to the first page on search
    await getData(value);
  };

  const downloadCsv = async () => {
    setCSVLoading(true);
    const response: any = await getProductCsv();

    if (response?.data?.length > 0) {
      const blob = new Blob([response?.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "downloaded_file.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    setCSVLoading(false);
  };
  const isVideoFile = (url: string) => {
    return url?.toLowerCase().endsWith(".mp4");
  };

  const columns = [
    {
      title: " ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_: string, obj: ProductType) => (
        <div
          className="users_image_box"
          style={{
            backgroundImage: `url(${
              obj?.imagesUrl[0]
                ? obj?.imagesUrl[0]
                : require("../../../assets/images/box.png")
            })`,
          }}
        >
          {isVideoFile(obj?.imagesUrl[0]) && (
            <video
              muted
              loop
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
              }}
            >
              <source src={obj?.imagesUrl?.[0]} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (t: string) => (
        <p>{t?.length > 100 ? `${t.slice(0, 150)}...` : t}</p>
      ),
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      render: (t: string) => <p>{firstLetterCap(t)}</p>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text: string, obj: ProductType) => <p>{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: string, obj: ProductType) => (
        <p
          style={{
            color: obj?.isActive ? "var(--mainGreen)" : "var(--mainRed)",
          }}
        >
          {obj?.isActive ? "Active" : "Inactive"}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (_: string, obj: ProductType) => (
        <div className="icon_box">
          <ViewProduct data={obj} />
          <TbEdit
            className="icon"
            onClick={() =>
              navigate("/create-product", {
                state: {
                  isEdit: true,
                  product: obj,
                },
              })
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Products</h1>
        <div>
          <UploadProductCsv getData={getData} />
          <Button onClick={() => navigate("/create-product")}>
            Create Product
          </Button>
          <Button loading={csvLoading} onClick={downloadCsv}>
            Download CSV
          </Button>
        </div>
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search..."
          onSearch={handleSearch}
          enterButton
          allowClear
          value={search}
          onChange={(e) => {
            const value = e.target.value;
            setSearch(value);
            if (value === "") {
              handleSearch("");
            }
          }}
        />
      </div>

      <div className="user_table_container">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={products}
          rowKey={(record) => record._id}
          scroll={{ x: 1200 }}
          pagination={{
            current: page,
            total: total,
            pageSize: perPage,
            showSizeChanger: true,
            onChange: (val) => {
              setPage(val);
            },
            onShowSizeChange(current, size) {
              setPerPage(size);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Product;
