import React, { useState } from "react";

import {
  Modal,
  Button,
  Row,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Avatar,
  InputNumber,
} from "antd";

import { TbEdit } from "react-icons/tb";

import {
  createCategory,
  getCategoriesByType,
  updateCategory,
} from "../../store/services/category";
import {
  CategoryType,
  CreateCategoryType,
} from "../../store/interfaces/Category";
import { useAppSelector } from "../../store/hooks";

interface Props {
  isEdit?: boolean;
  editObj?: CategoryType;
  getData: () => void;
  isMainCategory:boolean;
}

const CreateCategory: React.FC<Props> = ({ isEdit, editObj,isMainCategory }) => {
  const { typeCategories } = useAppSelector((state) => state.category);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<string>("");
  const [isSubCateg, setIsSubCateg] = useState<boolean>(false);
  const [file, setFile] = useState(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const _getCategoriesByType = async () => {
    setLoading("dropdown");
    await getCategoriesByType({ type: "category" });
    setLoading("");
  };

  const showModal = () => {
    _getCategoriesByType();
    setIsModalOpen(true);
    if (isEdit && editObj?.categoryType === "subcategory") {
      setIsSubCateg(true);
    }
    if (isEdit) {
      form.setFieldsValue({
        name: editObj?.name,
        categoryType: editObj?.categoryType,
        categoryId: Array.isArray(editObj?.categoryId)
          ? editObj?.categoryId.map((v) => v?._id)
          : [],
        sortNumber: editObj?.sortNumber,
        imageUrl: editObj?.imageUrl,
      });
      editObj?.imageUrl && setImageSrc(editObj?.imageUrl);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsSubCateg(false);
    setFile(null);
    setImageSrc(null);
    form.resetFields();
  };

  const onFinish = async (values: CreateCategoryType | any) => {
    console.log(values?.sortNumber)

    if (file) {
      let formdata = new FormData();
      formdata.append("_id", editObj?._id || "");
      formdata.append("name", values?.name || "");
      formdata.append("categoryType", values?.categoryType || "");
      formdata.append("sortNumber", values?.sortNumber);
      values?.categoryId &&
        formdata.append("categoryId", JSON.stringify(values?.categoryId) || "");
      formdata.append("storecsv", file || "");
      isEdit
        ? await _updateCategory(formdata)
        : await _createCategory(formdata);
    } else {
      const obj = {
        _id: editObj?._id || "",
        name: values?.name,
        categoryType: values?.categoryType,
        sortNumber: values?.sortNumber === undefined || values?.sortNumber === null ? 1 : values?.sortNumber,
        imageUrl: values?.imageUrl,
      };
      isEdit ? await _updateCategory(obj) : await _createCategory(obj);
    }
  };

  const _createCategory = async (payload: CreateCategoryType | any) => {
    setLoading("submit");
    const res = await createCategory(payload);
    if (res) {
      handleCancel();
    }
    setLoading("");
  };
  
  const _updateCategory = async (payload: CreateCategoryType | any) => {
    setLoading("submit");
    const res = await updateCategory(payload);
    if (res) {
      handleCancel();
    }
    setLoading("");
  };

  const handleFile = (file: any) => {
    setFile(file);
    const src: string = URL.createObjectURL(file);
    setImageSrc(src);
  };

  return (
    <>
      {isEdit ? (
        <TbEdit
          onClick={showModal}
          className="icon"
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Button onClick={showModal}>Create Category</Button>
      )}
      <Modal
        forceRender
        title={`${isEdit ? "Update Category" : "Create Category"}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="create_discount_modal"
        centered
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
          onValuesChange={(e) => {
            e.categoryType === "subcategory" && setIsSubCateg(true);
            e.categoryType === "category" && setIsSubCateg(false);
          }}
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <Avatar
                  size={55}
                  src={
                    imageSrc
                      ? imageSrc
                      : require("../../assets/images/cart.png")
                  }
                />
                <Form.Item
                  name={"imageUrl"}
                  label="Photo"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <>
                    <label className="file_button" htmlFor="fileInput">
                      <input
                        type="file"
                        hidden
                        id="fileInput"
                        onChange={(e: any) => {
                          handleFile(e?.target?.files[0]);
                        }}
                        accept="image/jpg,image/png,image/jpeg"
                      />
                    </label>
                  </>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"name"}
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Name (Required)" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"categoryType"}
                label="Category Type"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                disabled={isEdit}
                  placeholder="Select Category Type (Required)"
                  options={[
                    { value: "category", label: "Category" },
                    { value: "subcategory", label: "Sub Category" },
                  ]}
                />
              </Form.Item>
            </Col>

            {!isMainCategory && isSubCateg && !isEdit && (
              <Col xs={24}>
                <Form.Item
                  name={"categoryId"}
                  label="Main Category"
                  rules={[
                    {
                      required: isSubCateg ? true : false,
                      message: "Required",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    loading={loading === "dropdown"}
                    placeholder="Select Category"
                    className="users_select_box"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(
                      input: string,
                      option?: { label: string; value: string }
                    ) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={typeCategories
                      ?.filter((v) => v?._id !== editObj?._id)
                      ?.map((v) => {
                        return {
                          value: v?._id,
                          label: v?.name,
                        };
                      })}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24}>
              <Form.Item name={"sortNumber"} label="Sort Number" initialValue={1}  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]} >
                <InputNumber
                  // defaultValue={1}
                  min={1}
                  type="number"
                  placeholder="Sort Number (Required)"
                />
              </Form.Item>
            </Col>
            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button type="primary" onClick={handleCancel}>
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button
                  loading={loading === "submit"}
                  type="primary"
                  htmlType="submit"
                >
                  {isEdit ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default CreateCategory;
