import Axios from "axios";

// export const base_url = "http://192.168.100.31:5000";
// export const base_url = "http://192.168.100.11:5000";
// export const base_url = "http://192.168.100.35:5000";
export const base_url = "https://api.ocaapp.com";

// export const base_url = "https://oca-backend-new-production.up.railway.app/";

export const publicAPI = Axios.create({ baseURL: base_url });

export const privateAPI = Axios.create({ baseURL: base_url });

export const attachToken = async () => {
  const jwt = localStorage.getItem("token");
  privateAPI.defaults.headers.common.Authorization = `Bearer ${jwt}`;
};

export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=]).{8,}$/;

export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
